import React, {useCallback, useState} from 'react'
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'

const CustomColorPicker = (props) => {

    const handleColorChanged = props.handleColorChanged
    const color = props.color
    const key = props.element_key
    const [colorPickerFontColor, setColorPickerFontColor] = useState(color);
    const handleChangeColorPicker = useCallback((color) => {
        setColorPickerFontColor(color)
        //this is below the handler in app
        handleColorChanged(key, color.hex)
    }, [handleColorChanged, key]);

    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleClick = useCallback(() => {
        setDisplayColorPicker(!displayColorPicker)
    }, [displayColorPicker])

    const handleClose = useCallback(() => {
        setDisplayColorPicker(false)
    }, [])


    const styles = reactCSS({
        'default': {
            container: {
              position: 'relative',
            },
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: colorPickerFontColor.hex||color,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '15',
                right: 0,
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <div style={ styles.container }>
            <div style={ styles.swatch } onClick={ handleClick }>
                <div style={ styles.color } />
            </div>

        {
            displayColorPicker ? <div style={ styles.popover }>
                <div style={ styles.cover } onClick={ handleClose }/>
                <SketchPicker disableAlpha={true} color={ colorPickerFontColor } onChangeComplete={ handleChangeColorPicker } />
            </div> : null
        }

        </div>
    )
}

export default CustomColorPicker;