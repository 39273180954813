import React from 'react';
import ReactDOM from 'react-dom';
import '@shopify/polaris/styles.css';
import './index.scss'
import loadToken from './helpers/loadToken';
import parseJwt from './helpers/parseJWT';
import {AppProvider} from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';

import App from './App';

function WrappedApp() {
    return (
        <AppProvider i18n={enTranslations}>
            <App />
        </AppProvider>
    );
}
if (window.location === window.parent.location) {

    const jwtPayload = parseJwt(loadToken());
    console.log('Redirected to ensure embedded app mode')
    window.location.replace(jwtPayload.redirect_url);

} else {
    ReactDOM.render(<WrappedApp />, document.getElementById('root'));
}
