import React, {useCallback, useState} from 'react'
import {Banner, Card, DisplayText, FormLayout, Icon, Layout, Link, List, Select, TextContainer} from "@shopify/polaris"
import {CircleInformationMajorFilled} from "@shopify/polaris-icons"



const TabHelp = (props) => {



    const [selected, setSelected] = useState('simple');
    const handleSelectChange = useCallback((value) => {

        setSelected(value)
    }, []);

    let helpText = {productPage: {}, rating: {}, rating_collection: {}, info: {}};

    const options = [
        {label: 'Dawn', value: 'dawn'},
        {label: 'Simple', value: 'simple'},
        {label: 'Venture', value: 'venture'},
        {label: 'Brooklyn', value: 'brooklyn'},
        {label: 'Minimal', value: 'minimal'},
        {label: 'Supply', value: 'supply'},
        {label: 'Narrative', value: 'narrative'},
        {label: 'Express', value: 'express'},
        {label: 'Debut', value: 'debut'},
        {label: 'Boundless', value: 'boundless'},
        {label: 'Other, not free or custom themes', value: 'other'},
    ]

    helpText.info.title = (< div className = {"pre_icon"}><Icon
        source = {CircleInformationMajorFilled}/><p>The location of "{`{{ product.title }}`}" property is always a good point of reference.</p> </div>);

    helpText.productPage.dawn = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code".
            </List.Item>
            <List.Item>Check your <b>Sections/header.liquid</b> file. You should have this code in the bottom of the page:
                <pre><span>{`{{ shop.metafields.prismacart_review.shop_properties }}`}</span></pre>
                If you don’t see it insert the code and click ”Save” button.
            </List.Item>
            <List.Item>Find <b>Sections/main-product.liquid</b>, copy the line of code below:
                <pre><span>{`<div class="prismacart-review-container-{{ theme.name | downcase }}" style="display:none; width: 100%" id="prismacart-review-container" data-spd="{{ shop.permanent_domain }}" data-pi="{{ product.id }}"> {{ product.metafields.prismacart_review.reviews }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste the entire line of code wherever you want the widget to appear. The recommended place is in the bottom of the page.
                Insert the code as shown in the picture and click ”Save” button:
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_dawn.png"}/>
            </List.Item>
        </List>
    );

    helpText.productPage.simple = (
         <List type="number">
             <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code".
             </List.Item>
             <List.Item>Check your <b>Sections/header.liquid</b> file. You should have this code in the bottom of the page:
                 <pre><span>{`{{ shop.metafields.prismacart_review.shop_properties }}`}</span></pre>
                 If you don’t see it insert the code and click ”Save” button.
             </List.Item>
             <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                 <pre><span>{`<div class="page-width prismacart-review-container-{{ theme.name | downcase }}" style="display:none" id="prismacart-review-container" data-spd="{{ shop.permanent_domain }}" data-pi="{{ product.id }}"> {{ product.metafields.prismacart_review.reviews }}</div>`}</span></pre>
             </List.Item>
             <List.Item>Paste the entire line of code wherever you want the widget to appear. The recommended place is in the bottom of the page.
                 Insert the code as shown in the picture and click ”Save” button:
                 <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_simple.png"}/>
              </List.Item>
         </List>
     );

    helpText.productPage.venture = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code".</List.Item>
            <List.Item>Check your <b>Sections/header.liquid</b> file. You should have this code in the bottom of the page:
                <pre><span>{`{{ shop.metafields.prismacart_review.shop_properties }}`}</span></pre>
                If you don’t see it insert the code and click ”Save” button.
            </List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div class="page-width prismacart-review-container-{{ theme.name | downcase }}" style="display:none" id="prismacart-review-container" data-spd="{{ shop.permanent_domain }}" data-pi="{{ product.id }}"> {{ product.metafields.prismacart_review.reviews }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste the entire line of code wherever you want the widget to appear. The recommended place is in the bottom of the page.
                Insert the code as shown in the picture and click ”Save” button:
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_venture.png"}/>
            </List.Item>
        </List>
    );

    helpText.productPage.brooklyn = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code".</List.Item>
            <List.Item>Check your <b>Sections/header.liquid</b> file. You should have this code in the bottom of the page:
                <pre><span>{`{{ shop.metafields.prismacart_review.shop_properties }}`}</span></pre>
                If you don’t see it insert the code and click ”Save” button.
            </List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div class="prismacart-review-container-{{ theme.name | downcase }}" style="display:none" id="prismacart-review-container" data-spd="{{ shop.permanent_domain }}" data-pi="{{ product.id }}"> {{ product.metafields.prismacart_review.reviews }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste the entire line of code wherever you want the widget to appear. The recommended place is in the bottom of the page.
                Insert the code as shown in the picture and click ”Save” button.
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_brooklyn.png"}/>
            </List.Item>
        </List>
    );

    helpText.productPage.minimal = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Check your <b>Sections/header.liquid</b> file. You should have this code in the bottom of the page:
                <pre><span>{`{{ shop.metafields.prismacart_review.shop_properties }}`}</span></pre>
                If you don’t see it insert the code and click ”Save” button.
            </List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div class="prismacart-review-container-{{ theme.name | downcase }}" style="display:none" id="prismacart-review-container" data-spd="{{ shop.permanent_domain }}" data-pi="{{ product.id }}"> {{ product.metafields.prismacart_review.reviews }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste the entire line of code wherever you want the widget to appear. The recommended place is in the bottom of the page.
                Insert the code as shown in the picture and click ”Save” button.
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_minimal.png"}/>
                </List.Item>
        </List>
    );

    helpText.productPage.debut = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Check your <b>Sections/header.liquid</b> file. You should have this code in the bottom of the page:
                <pre><span>{`{{ shop.metafields.prismacart_review.shop_properties }}`}</span></pre>
                If you don’t see it insert the code and click ”Save” button.
            </List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div class="prismacart-review-container-{{ theme.name | downcase }}  page-width" style="display:none" id="prismacart-review-container" data-spd="{{ shop.permanent_domain }}" data-pi="{{ product.id }}"> {{ product.metafields.prismacart_review.reviews }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste the entire line of code wherever you want the widget to appear. The recommended place is in the bottom of the page.
                Insert the code as shown in the picture and click ”Save” button.
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_debut.png"}/>
            </List.Item>
        </List>
    );

    helpText.productPage.supply = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Check your <b>Sections/header.liquid</b> file. You should have this code in the bottom of the page:
                <pre><span>{`{{ shop.metafields.prismacart_review.shop_properties }}`}</span></pre>
                If you don’t see it insert the code and click ”Save” button.
            </List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div class="prismacart-review-container-{{ theme.name | downcase }}" style="display:none" id="prismacart-review-container" data-spd="{{ shop.permanent_domain }}" data-pi="{{ product.id }}"> {{ product.metafields.prismacart_review.reviews }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste the entire line of code wherever you want the widget to appear. The recommended place is in the bottom of the page.
                Insert the code as shown in the picture and click ”Save” button.
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_supply.png"}/>
            </List.Item>
        </List>
    );

    helpText.productPage.narrative = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Check your <b>Sections/header.liquid</b> file. You should have this code in the bottom of the page:
                <pre><span>{`{{ shop.metafields.prismacart_review.shop_properties }}`}</span></pre>
                If you don’t see it insert the code and click ”Save” button.
            </List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-review-container-{{ theme.name | downcase }}" id="prismacart-review-container" data-spd="{{ shop.permanent_domain }}" data-pi="{{ product.id }}"> {{ product.metafields.prismacart_review.reviews }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste the entire line of code wherever you want the widget to appear. The recommended place is between the description and the share buttons.
                Insert the code as shown in the picture and click ”Save” button.
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_narrative.png"}/>
            </List.Item>
        </List>
    );

    helpText.productPage.express = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Check your <b>Sections/header.liquid</b> file. You should have this code in the bottom of the page:
                <pre><span>{`{{ shop.metafields.prismacart_review.shop_properties }}`}</span></pre>
                If you don’t see it insert the code and click ”Save” button.
            </List.Item>
            <List.Item>Find <b>Templates/product.liquid</b>, copy the line of code below:
                <pre><span>{`<div class="product product--template prismacart-review-container-{{ theme.name | downcase }}" style="display:none" id="prismacart-review-container" data-spd="{{ shop.permanent_domain }}" data-pi="{{ product.id }}"> {{ product.metafields.prismacart_review.reviews }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste the entire line of code wherever you want the widget to appear. The recommended place is between the description and the share buttons.
                Insert the code as shown in the picture and click ”Save” button.
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_express.png"}/>
            </List.Item>
        </List>
    );

    helpText.productPage.boundless = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Check your <b>Sections/header.liquid</b> file. You should have this code in the bottom of the page:
                <pre><span>{`{{ shop.metafields.prismacart_review.shop_properties }}`}</span></pre>
                If you don’t see it insert the code and click ”Save” button.
            </List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-review-container-{{ theme.name | downcase }}" id="prismacart-review-container" data-spd="{{ shop.permanent_domain }}" data-pi="{{ product.id }}"> {{ product.metafields.prismacart_review.reviews }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste the entire line of code wherever you want the widget to appear. The recommended place is between the description and the share buttons.
                Insert the code as shown in the picture and click ”Save” button.
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_boundless.png"}/>
            </List.Item>
        </List>
    );

    helpText.productPage.other = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code".
            </List.Item>
            <List.Item>Check your <b>Sections/header.liquid</b> file. You should have this code in the bottom of the page:
                <pre><span>{`{{ shop.metafields.prismacart_review.shop_properties }}`}</span></pre>
                If you don’t see it insert the code and click ”Save” button.
            </List.Item>
            <List.Item>Find the template or section you use for product pages. It is usually called <b>product.liquid / product-template.liquid / product-page.liquid or propduct-main.liquid</b>, but some themes work differently. Copy the line of code below:
                <pre><span>{`<div class="prismacart-review-container-{{ theme.name | downcase }}" style="display:none; width: 100%" id="prismacart-review-container" data-spd="{{ shop.permanent_domain }}" data-pi="{{ product.id }}"> {{ product.metafields.prismacart_review.reviews }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste the entire line of code wherever you want the widget to appear. The recommended place is in the bottom of the page.
                Insert the code as shown in the picture and click ”Save” button:
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_dawn.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating.dawn = (
        <List type="number">
            <List.Item>In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Find <b>Sections/main-product.liquid</b>, copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-ind-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title.
                Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_dawn_avgrating.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating.simple = (
        <List type="number">
            <List.Item>In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-ind-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title.
                Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_simple_avgrating.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating.venture = (
        <List type="number">
            <List.Item>In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-ind-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title.
                Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_venture_avgrating.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating.brooklyn = (
        <List type="number">
            <List.Item>In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-ind-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_brooklyn_avgrating.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating.minimal = (
        <List type="number">
            <List.Item>In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-ind-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_minimal_avgrating.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating.supply = (
        <List type="number">
            <List.Item>In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-ind-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_supply_avgrating.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating.narrative = (
        <List type="number">
            <List.Item>In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-ind-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_narrative_avgrating.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating.debut = (
        <List type="number">
            <List.Item>In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-ind-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_debut_avgrating.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating.boundless = (
        <List type="number">
            <List.Item>In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Find <b>Sections/product-template.liquid</b>, copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-ind-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_boundless_avgrating.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating.express = (
        <List type="number">
            <List.Item>In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Find <b>Sections/product.liquid</b>, copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-ind-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_express_avgrating.png"}/>
           </List.Item>
        </List>
    );

    helpText.rating.other = (
        <List type="number">
            <List.Item>In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>Find <b>Sections/main-product.liquid or Sections/product.liquid</b>, but in some cases other section or template is responsible for product pages. Copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-ind-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title.
                Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_dawn_avgrating.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating_collection.dawn = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>
                Find <b>product-card.liquid</b> copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-coll-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product_card_product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_dawn_collection.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating_collection.simple = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>
                Find <b>product-grid-item.liquid</b> copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-coll-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_simple_collection.png"}/>
            </List.Item>
        </List>
    );
    helpText.rating_collection.venture = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>
                Find <b>product-card.liquid</b> copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-coll-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_venture_collection.png"}/>
            </List.Item>
        </List>
    );
    helpText.rating_collection.brooklyn = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>
                Find <b>product-grid-item.liquid</b> copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-coll-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_brooklyn_collection.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating_collection.minimal = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>
                Find <b>product-grid-item.liquid</b> copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-coll-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ featured.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_minimal_collection.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating_collection.supply = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>
                Find <b>product-grid-item.liquid and product-list-item.liquid</b> copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-coll-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_supply_collection.png"}/>
            </List.Item>
        </List>
    );
    helpText.rating_collection.narrative = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>
                Find <b>product-card.liquid</b> copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-coll-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_narrative_collection.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating_collection.express = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>
                Find <b>product-card-list.liquid</b> copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-coll-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_express_collection.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating_collection.debut = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>
                Find  <b>product-card-grid.liquid</b> copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-coll-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_debut_collection.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating_collection.boundless = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>
                Find  <b>product-grid-item.liquid</b> copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-coll-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_boundless_collection.png"}/>
            </List.Item>
        </List>
    );

    helpText.rating_collection.other = (
        <List type="number">
            <List.Item>Go to your Shopify Themes menu. In Shopify panel go to Online store, and then choose Themes. In current theme the Actions menu should now be available. Click the "Actions" menu and then select "Edit code"</List.Item>
            <List.Item>
                Find <b>product-card-list.liquid / product-card.liquid / product-grid-item.liquid / product-card-grid.liquid</b>, but in some cases other section or template is responsible for collections. Copy the line of code below:
                <pre><span>{`<div style="display:none" class="prismacart-avgrating-coll-container-{{ theme.name | downcase }} prismacart-avgrating-ind-container">{{ product_card_product.metafields.prismacart_review.avgRating }}</div>`}</span></pre>
            </List.Item>
            <List.Item>Paste it where you want the stars to appear. The recommended place is below the product's title. Insert the code as shown in the picture and click ”Save” button.
                {helpText.info.title}
                <img alt="insert code" className={"help_image"} src={"https://prismacart-review.s3.amazonaws.com/assets/image/help/instruction_dawn_collection.png"}/>
            </List.Item>
        </List>
    );

    return (
            <Layout>
                <Layout.Section>
                    <Card title="1. How to integrate the Prismacart Review widget with your product pages?" sectioned>
                        <Card.Section>
                            <Banner status="info">
                                Watch our instructional{' '}
                                <Link url="https://youtu.be/1k_GOhduRh8" external>video</Link>{' '}about how to integrate the review widget!
                            </Banner>
                            <p>&nbsp;</p>
                            <p>
                                <DisplayText size="medium">The application automatically implements the necessary settings on the active theme.</DisplayText>
                            </p>
                            <p>
                                However, for example, when changing themes, you may need to reconfigure the application according to the instructions below.
                            </p>
                        </Card.Section>
                        <Card.Section>
                            <FormLayout>
                                    <Select
                                        label="Select the theme for which you want to implement the review widget!"

                                        options={options}
                                        onChange={handleSelectChange}
                                        value={selected}
                                    />
                            </FormLayout>
                        </Card.Section>
                        <Card.Section>
                            <TextContainer spacing="loose">
                                { helpText.productPage[selected] }
                            </TextContainer>
                        </Card.Section>
                        <Card.Section>
                            <TextContainer spacing="loose">
                                <p>If reviews still don't appear, please contact our <a href={"mailto:info@prismacart.com"}>support</a> and we'll be happy to assist!</p>
                            </TextContainer>
                        </Card.Section>
                    </Card>
                </Layout.Section>
                <Layout.Section>
                    <Card title="2. How to display star ratings on product page?" sectioned>
                        <Card.Section>
                            <Banner status="info">
                                Watch our instructional{' '}
                                <Link url="https://youtu.be/AkGnOKnoTNw" external>video</Link>{' '}about how to integrate star ratings widget on product page!
                            </Banner>
                            <p>&nbsp;</p>
                            <p>
                                To see the Prismacart star ratings appear on products that include reviews, you can manually add them to the product page.
                            </p>
                        </Card.Section>

                        <Card.Section>
                            <FormLayout>
                                <Select
                                    label="Select the theme for which you want to implement the review widget!"

                                    options={options}
                                    onChange={handleSelectChange}
                                    value={selected}
                                />
                            </FormLayout>
                        </Card.Section>
                        <Card.Section>
                            { helpText.rating[selected] }
                            </Card.Section>

                        <Card.Section>
                            <p>If reviews still don't appear, please contact our <a href={"mailto:info@prismacart.com"}>support</a> and we'll be happy to assist!</p>
                        </Card.Section>
                    </Card>
                </Layout.Section>

                <Layout.Section>
                    <Card title="3. How to display star ratings on collection page?" sectioned>
                        <Card.Section>
                            <Banner status="info">
                                Watch our instructional{' '}
                                <Link url="https://youtu.be/6ZjujvCw3qI" external>video</Link>{' '}about how to integrate star ratings widget on collection page!
                            </Banner>
                            <p>&nbsp;</p>
                            <p>To see the Prismacart star ratings appear on products that include reviews, you can manually add them to the collection page.</p>
                        </Card.Section>
                        <Card.Section>
                            <FormLayout>
                                <Select
                                    label="Select the theme for which you want to implement the review widget!"
                                    options={options}
                                    onChange={handleSelectChange}
                                    value={selected}
                                />
                            </FormLayout>
                        </Card.Section>

                        <Card.Section>
                            { helpText.rating_collection[selected] }
                        </Card.Section>
                        <Card.Section>
                            <p>If reviews still don't appear, please contact our <a href={"mailto:info@prismacart.com"}>support</a> and we'll be happy to assist!</p>
                        </Card.Section>
                    </Card>
                </Layout.Section>

                <Layout.Section>
                    <Card title="4. Uninstall application snippets" sectioned>
                        <Card.Section>
                            <p>
                                We're sorry to see you go, but we realize the app doesn't work for everyone. Below are instructions how to uninstall the app.
                            </p>
                        </Card.Section>

                        <Card.Section>
                            <p>
                                One of the common reason people uninstall the app is they have trouble using it. If you decided that this app is not for you, or you're sure you want to uninstall it then follow the instructions below. However, if you just have a question, confused with the instructions, or are just having trouble using it we'd love to help! Shoot us an email at info@prismacart.com and let us know and we'll do what we can to help.
                            </p>
                        </Card.Section>

                        <Card.Section>
                            <p>
                                Simply head over to your Shopify admin and in the Apps section find the app and remove it from there. When you removed the application just follow steps 1 to 3 and remove our code snippets from your templates.
                            </p>
                        </Card.Section>
                    </Card>
                </Layout.Section>

            </Layout>
    )
}

export default TabHelp;