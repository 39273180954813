import React, {useState, useCallback} from 'react'
import {
    Layout,
    Page,
    FooterHelp,
    Card,
    Link,
    Button,
    FormLayout,
    TextField,
    ChoiceList,
    SettingToggle,
    Form,
    TextStyle,
    Toast,
    Frame,
    ResourceList,
    Filters,
    Pagination,
    Autocomplete,
    Icon,
    Modal,
    TextContainer,
    Badge,
    Stack,
    ButtonGroup,
    List,
    DisplayText,
    Banner,
    Checkbox,
    Select,
    RadioButton,
    RangeSlider,
    Loading, Subheading
} from '@shopify/polaris'

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs'
import {IncomingMajorMonotone} from '@shopify/polaris-icons'
import {SearchMinor} from '@shopify/polaris-icons'
import {SettingsMajorMonotone} from '@shopify/polaris-icons'
import {ListMajorMonotone} from '@shopify/polaris-icons'
import {CashDollarMajorMonotone} from '@shopify/polaris-icons'
import {ColorsMajorMonotone} from '@shopify/polaris-icons';
import {QuestionMarkMajorMonotone} from '@shopify/polaris-icons';
import {EditMinor} from '@shopify/polaris-icons';

import CustomColorPicker from './components/CustomColorPicker'
import CustomAEImportItem from "./components/CustomAEImportItem"
import TabHelp from "./components/TabHelp"
import loadToken from './helpers/loadToken';

const _ = require("underscore")

const {useEffect, useRef} = require("react")

let token = ''

export default function App(callback, deps) {


    const usePrevious = value => {
        const ref = useRef()
        useEffect(() => {
            ref.current = value
        })
        return ref.current
    }

    const [aliexpressUrl, setAliexpressUrl] = useState('')
    const [tabIndex, setTabIndex] = useState(4)
    const [charged, setCharged] = useState(false)

    const [chargedLimit, setChargedLimit] = useState(10);

    const [toastImported, setToastImported] = useState(false)
    const toggleToastImported = useCallback(() => setToastImported((toastImported) => !toastImported), [])
    const toastImportedMarkup = toastImported ? (
        <Toast
            content="Reviews have been imported!"
            duration={1000}
            onDismiss={toggleToastImported}
        />
    ) : null;

    const [toastLimitReached, setToastLimitReached] = useState(false)
    const toggleToastLimitReached = useCallback(() => setToastLimitReached((toastLimitReached) => !toastLimitReached), [])

    const toastLimitReachedMarkup = toastLimitReached ? (
        <Toast
            content={'Max ' + chargedLimit + ' reviews selected!'}
            duration={3000}
            onDismiss={toggleToastLimitReached}
        />
    ) : null;

    const [toastActive, setToastActive] = useState(false)
    const toggleToastActive = useCallback(() => setToastActive(toastActive => !toastActive), [])
    const toastMarkup = toastActive ? <Toast content='Saved!' onDismiss={toggleToastActive} duration={1000}/> : null

    const [toastSaveDelay, setToastSaveDelay] = useState(false)
    const toggleToastSaveDelay = useCallback(() => setToastSaveDelay(toastSaveDelay => !toastSaveDelay), [])
    const toastSaveDelayMarkup = toastSaveDelay ? <Toast content='Store appearance will be updated in a minute!' onDismiss={toggleToastSaveDelay} duration={2000}/> : null

    const [toastError, setToastError] = useState(false)
    const toggleToastError = useCallback(() => setToastError(toastError => !toastError), [])
    const toastErrorMarkup = toastError ?
        <Toast error content='Error happened!' onDismiss={toggleToastError} duration={2000}/> : null

    const [active, setActive] = useState(false)
    const handleToggle = useCallback(() => {
        setProgressBar(true);
        saveShopInfo({auto_review_approve: !active}).then((resultProperties) => {
            setActive(resultProperties.auto_review_approve)
            setToastActive(toastActive => !toastActive)
            setProgressBar(false);
        }).catch((e) => {
            setToastError(true);
            setProgressBar(false);
        })
    }, [active])

    const [reviewEmailActive, setReviewEmailActive] = useState(false)
    const handleReviewEmailToggle = useCallback(() => {
        setProgressBar(true);
        saveShopInfo({review_email: !reviewEmailActive}).then((resultProperties) => {
            setReviewEmailActive(resultProperties.review_email)
            setToastActive(toastActive => !toastActive)
            setProgressBar(false);
        }).catch((e) => {
            setToastError(true);
            setProgressBar(false);
        })
    }, [reviewEmailActive])

    const [reviewEmailRequestActive, setReviewEmailRequestActive] = useState(false)
    const handleReviewEmailRequestToggle = useCallback(() => {
        setProgressBar(true);
        saveShopInfo({review_email_request: !reviewEmailRequestActive}).then((resultProperties) => {
            setReviewEmailRequestActive(resultProperties.review_email_request)
            setToastActive(toastActive => !toastActive)
            setProgressBar(false);
        }).catch((e) => {
            setToastError(true)
            setProgressBar(false);
        })
    }, [reviewEmailRequestActive])

    const handleTabIndexChange = useCallback((value) => {
        setTabIndex(value)
        if (value === 1) {
            setSelectedItems([])
            setOffset(0)
            setListVersion(listVersion => listVersion + 1)
        }
    }, [tabIndex]);

    const handleChargedChange = useCallback((value) => {
        setCharged(value)
        if (value) {
            handleTabIndexChange(4)
        } else {
            handleTabIndexChange(4)
        }
    }, [charged])

    const handleChargeLimitChange = useCallback((value) => {
        setChargedLimit(chargeOptionSet[value].importLimit)
    }, [chargedLimit])

    const contentStatus = active ? 'Deactivate' : 'Activate'
    const textStatus = active ? 'activated' : 'deactivated'

    const autoApproveDescription = active
        ? 'Deactivate auto approve if you want to check reviews manually.'
        : 'Activate auto approve option to approve all review automatically.'

    const reviewEmailContentStatus = reviewEmailActive ? 'Deactivate' : 'Activate'
    const reviewEmailTextStatus = reviewEmailActive ? 'activated' : 'deactivated'

    const reviewEmailDescription = reviewEmailActive
        ? 'Deactivate email notification if you do not want to be notified about new reviews.'
        : 'Activate email notification if you want to be notified about new reviews.'

    const reviewEmailRequestContentStatus = reviewEmailRequestActive ? 'Deactivate' : 'Activate'
    const reviewEmailRequestTextStatus = reviewEmailRequestActive ? 'activated' : 'deactivated'

    const reviewEmailRequestDescription = reviewEmailRequestActive
        ? 'Deactivate review request email if you do not want auto review request emails to customers.'
        : 'Activate review request email if you do want auto review request emails to customers.'


    useEffect(() => {
        initialProcess().then(() => {
        }).catch((e) => {
            setToastError(true)
        })
    }, [])

    function disambiguateLabel(key, value) {
        switch (key) {
            case 'approveStatus':
                return value.map((val) => `Approved ${val}`).join(', ')
            default:
                return value
        }
    }

    function isEmpty(value) {
        if (Array.isArray(value)) {
            return value.length === 0
        } else {
            return value === '' || value == null
        }
    }

    const [items, setItems] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [approveStatus, setApproveStatus] = useState(null)
    const [queryValue, setQueryValue] = useState(null)
    const [loading, setLoading] = useState(false)
    const [productSearchField, setProductSearchField] = useState([])

    const [hasNext, setHasNext] = useState(false)
    const [hasPrevious, setHasPrevious] = useState(false)
    const [limit, setLimit] = useState(2)
    const [offset, setOffset] = useState(0)
    const [listVersion, setListVersion] = useState(0)

    const promotedBulkActions = [
        {
            content: 'Approve reviews',
            onAction: () => handleApproveReviews(),
        },
        {
            content: 'Reject reviews',
            onAction: () => handleDisapproveReviews(),
        }
    ]

    const bulkActions = [
        {
            content: 'Delete reviews',
            onAction: () => setDeleteReviewModalOpen(true),
        },
    ]

    const queryParams = {productKeyword: productSearchField, approved: approveStatus, keyword: queryValue, limit, offset, listVersion}
    const myPreviousqueryParams = usePrevious(queryParams)

    const handleApproveReviews = useCallback(() => {
        setProgressBar(true);
        approveReviews(selectedItems).then(() => {
            setToastActive(true)
            setSelectedItems([])
            setOffset(0)
            setListVersion(listVersion => listVersion + 1)
            setProgressBar(false);
        }).catch((e) => {
            setToastError(true)
            setProgressBar(false);
        })
    }, [selectedItems])

    const handleDisapproveReviews = useCallback(() => {
        setProgressBar(true);
        disapproveReviews(selectedItems).then(() => {
            setToastActive(true)
            setSelectedItems([])
            setOffset(0)
            setListVersion(listVersion => listVersion + 1)
            setProgressBar(false);
        }).catch((e) => {
            setToastError(true)
            setProgressBar(false);
        })
    }, [selectedItems])

    const handleDeleteReviews = useCallback(() => {
        handleDeleteReviewModalClose();
        setProgressBar(true);
        deleteReviews(selectedItems).then(() => {
            setToastActive(true)
            setSelectedItems([])
            setOffset(0)
            setListVersion(listVersion => listVersion + 1)
            setProgressBar(false);
        }).catch((e) => {
            setToastError(true)
            setProgressBar(false);
        })
    }, [selectedItems])

    useEffect(() => {
        setLimit(50)
    }, [])

    useEffect(() => {
        setOffset(0)
    }, [approveStatus, queryValue])

    useEffect(() => {
            if (myPreviousqueryParams && !_.isEqual(myPreviousqueryParams, queryParams)) {
                const passParams = JSON.parse(JSON.stringify(queryParams))
                setLoading(true)
                listReviews(passParams)
                    .then((response) => {
                        setItems(response.docs.map(obj => ({...obj, id: obj._id})))
                        setLoading(false)
                        setHasPrevious(response.hasPrevPage)
                        setHasNext(response.hasNextPage)
                        setSelectedItems([])
                    })
                    .catch((e) => {
                        setToastError(true)
                        setItems([])
                        setLoading(false)
                    })
            }
        }, [queryParams, myPreviousqueryParams]
    )


    const handleApproveStatusChange = useCallback((value) => {
            setApproveStatus(value)
        },
        [],
    )

    const handleFiltersQueryChange = useCallback((value) => {
            setQueryValue(value)
        },
        []
    )
    const handleApproveStatusRemove = useCallback(() => {
            setApproveStatus(null)
        },
        []
    )
    const handleQueryValueRemove = useCallback(() => {
            setQueryValue(null)
        },
        []
    )

    const handleProductSearchFieldChange = useCallback((value) => {
            setProductSearchField(value)
        },
        [],
    )

    const handleProductSearchFieldRemove = useCallback(() => {
            setProductSearchField(null)
        },
        []
    )

    const handleFiltersClearAll = useCallback(() => {
        handleApproveStatusRemove()
        handleQueryValueRemove()
        handleProductSearchFieldRemove()
    }, [
        handleApproveStatusRemove,
        handleQueryValueRemove,
        handleProductSearchFieldRemove
    ])

    const filters = [
        {
            key: 'product',
            label: 'Product filter',
            filter: (
                <TextField label="Product name" value={productSearchField} onChange={handleProductSearchFieldChange} />
            ),
            shortcut: true,
        },
        {
            key: 'approveStatus',
            label: 'Approve status',
            filter: (
                <ChoiceList
                    title="Approve status"
                    titleHidden
                    choices={[
                        {label: 'Approved', value: true},
                        {label: 'Not approved', value: false},
                    ]}
                    selected={approveStatus || []}
                    onChange={handleApproveStatusChange}
                    allowMultiple
                />
            ),
            shortcut: true,
        }
    ]

    const appliedFilters = []
    if (!isEmpty(approveStatus)) {
        const key = 'approveStatus'
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, approveStatus),
            onRemove: handleApproveStatusRemove,
        })
    }
    if (!isEmpty(productSearchField)) {
        const key = 'product'
        appliedFilters.push({
            key,
            label: disambiguateLabel(key, productSearchField),
            onRemove: handleProductSearchFieldRemove,
        })
    }

    //this is the end of filtering

    const deselectedOptions = []
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState({})

    const [importableChecked, setImportableChecked] = useState({});

    const [addingDisabled, setAddingDisabled] = useState(false);

    const importAEButtonDisabled = (Object.keys(selectedOption).length === 0 && selectedOption.constructor === Object) || (Object.keys(importableChecked).length === 0 && importableChecked.constructor === Object) ? true : false


    const handleImportableCheckedChange = useCallback((newChecked, id) => {

        importableChecked[id] = newChecked

        let currentImportableChecked = {}
        let numberOfChecked = 0;


         Object.keys(importableChecked).map((key) => {
            if (importableChecked[key] === true) {
                currentImportableChecked[key] = true
                numberOfChecked++;
            }
        })

        if (numberOfChecked >= chargedLimit) {
            setAddingDisabled(true);
            setToastLimitReached(true);
        } else {
            setAddingDisabled(false);
        }

        setImportableChecked(currentImportableChecked);
    }, [importableChecked, addingDisabled, charged, chargedLimit]);

    async function loadShopInfo() {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })

        try {
            const res = await fetch("/api/shop", {headers})
            return await res.json()
        } catch (e) {
            return (e)
        }
    }

    function setToken() {
        token = loadToken();
    }

    async function initialProcess() {
        setToken()
        try {
            const response = await loadShopInfo()
            setActive(response.auto_review_approve);
            setReviewEmailActive(response.review_email);
            setReviewEmailRequestActive(response.review_email_request);

            if (typeof response.charge != "undefined") {
                handleChargedChange(response.charge.name);
                handleChargeLimitChange(response.charge.name);
            } else {
                handleChargeLimitChange('free');
            }

            if (response.appearance) {
                setAppearance(response.appearance)
            }
        } catch (e) {
            setToastError(true)
        }
    }

    async function saveShopInfo(properties) {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
        const method = 'PATCH'
        const body = JSON.stringify(properties)

        try {
            const res = await fetch("/api/shop", {
                headers,
                method,
                body
            })
            return await res.json()
        } catch (e) {
            return e
        }
    }

    async function modifyReview(id, properties) {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
        const method = 'PATCH'
        const body = JSON.stringify(properties)

        try {
            const res = await fetch("/api/review/" + id, {
                headers,
                method,
                body
            })
            return await res.json()
        } catch (e) {
            return e
        }
    }

    async function approveReviews(ids) {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
        const method = 'PATCH'
        const body = JSON.stringify({ids})

        try {
            const res = await fetch("/api/reviews/approve", {
                headers,
                method,
                body
            })
            return await res.json()
        } catch (e) {
            return e
        }
    }

    async function disapproveReviews(ids) {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
        const method = 'PATCH'
        const body = JSON.stringify({ids})

        try {
            const res = await fetch("/api/reviews/disapprove", {
                headers,
                method,
                body
            })
            return await res.json()
        } catch (e) {
            return e
        }
    }

    async function deleteReviews(ids) {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
        const method = 'DELETE'
        const body = JSON.stringify({ids})

        try {
            const res = await fetch("/api/reviews/delete", {
                headers,
                method,
                body
            })
            return await res.json()
        } catch (e) {
            return e
        }
    }

    async function listReviews(params) {
        function filterParams(params) {
            let filteredParams = {}
            for (let key in params) {
                if (!Object.prototype.hasOwnProperty.call(params, key)) {
                    continue
                }
                if (_.isUndefined(params[key]) || _.isNull(params[key])) {
                    continue
                }
                filteredParams[key] = params[key]
            }
            return filteredParams
        }

        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
        const method = 'GET'

        if (params.approved && params.approved.length > 1) {
            params.approved = null
        } else if (params.approved) {
            params.approved = params.approved[0]
        } else {
            params.approved = null
        }
        params = filterParams(params)


        const esc = encodeURIComponent
        const query = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&')
        try {
            const res = await fetch("/api/reviews?" + query, {
                headers,
                method,
            })
            return await res.json()
        } catch (e) {
            throw e
        }
    }

    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState(deselectedOptions)

    const updateText = useCallback(
        (value) => {
            setInputValue(value)

            if (!loading) {
                setLoading(true)
            }

            async function queryProducts(value) {
                try {
                    const headers = new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    })
                    const method = 'GET'
                    const res = await fetch("/api/shop/products/autocomplete?title=" + value, {
                        headers,
                        method
                    })
                    return await res.json()
                } catch (e) {
                    return Error(e)
                }
            }

            if (value === '') {
                setOptions(deselectedOptions)
                setLoading(false)
                return
            }

            queryProducts(value).then((result) => {
                //setOptions(results);
                //setLoading(false);
                const products = result.data.products.edges.map(obj => ({
                    value: obj.node.legacyResourceId,
                    label: obj.node.title,
                    image: obj.node.images.edges.length ? obj.node.images.edges[0].node.src : null
                }))
                setOptions(products)
                setLoading(false)
            }).then((e) => {
                setLoading(false)
            })
        },
        [deselectedOptions, loading],
    )

    const updateSelection = useCallback((selected) => {

        const selectedCurrentOption = options.find((option) => {
            return option.value.match(selected)
        })

        setSelectedOption(selectedCurrentOption)
        setSelectedOptions(selected)
        setInputValue(selectedCurrentOption.label)
    }, [options])


    const textFields = (
        <Autocomplete.TextField
            onChange={updateText}
            label="Product"
            value={inputValue}
            prefix={<Icon source={SearchMinor} color="inkLighter"/>}
            placeholder="Search"
        />
    )

    const thumbnail = selectedOption.image
        ? (<div style={{float: "left", margin: "0 10px 0 0"}}><img style={{width: "40px"}} src={selectedOption.image}
                                                                   alt={selectedOption.label}/></div>)
        : ''

    const selectedProductBox = (
        <div style={{overflow: "auto"}}>
            {thumbnail}
            <h3>
                <TextStyle variation="strong">{selectedOption.label ? selectedOption.label : ''}</TextStyle>
            </h3>
        </div>
    )



    const [checkedReviewWithImagesOnly, setCheckedReviewWithImagesOnly] = useState(false);
    const handleReviewWithImagesOnly = useCallback((newChecked) => setCheckedReviewWithImagesOnly(newChecked), []);


    const [progressBar, setProgressBar] = useState(false)
    const importReviewsLoading = progressBar ? <Loading /> : null

    const [AEItems, setAEItems] = useState([])
    const [selectedAEItems, setSelectedAEItems] = useState([])
    const [AELoading, setAELoading] = useState(false)


    const [selectedNameGeneratorStyle, setSelectedNameGeneratorStyle] = useState('random');

    const handleNameGeneratorStyles = useCallback((value) => setSelectedNameGeneratorStyle(value), []);

    const nameGeneratorStylesOptions = [
        {label: 'Random style usernames', value: 'random'},
        {label: 'Common names', value: 'normal'},
        {label: 'Names suffixed by a number', value: 'style1'},
        {label: 'Lowercase names suffixed by numbers ', value: 'style2'},
        {label: 'AliExpress style usernames', value: 'aliexpress'},
    ];

    const [checkedSurname, setCheckedSurname] = useState(true);
    const handleSurname = useCallback((newChecked) => setCheckedSurname(newChecked), []);


    const [nameGender, setNameGender] = useState('both');

    const handleNameGender = useCallback((value) => setNameGender(value), []);

    const nameGenderOptions = [
        {label: 'Female and male names', value: 'both'},
        {label: 'Female names only', value: 'female'},
        {label: 'Male names only', value: 'male'},
    ];

    const promotedAEBulkActions = [
        {
            content: 'Import reviews',
            onAction: () => handleImportAEReviews(),
        }
    ]

    const handleSelectedAEItemsChange = useCallback((items) => {
        setSelectedAEItems(items)
    }, [selectedAEItems])

    const handleImportAEReviews = useCallback(() => {

        async function importReviews(reviews) {
            try {
                const headers = new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                })
                const body = JSON.stringify({
                    product_id: selectedOption.value,
                    reviews,
                })

                const method = 'POST'
                const response = await fetch("/api/shop/aliexpress/review/import", {
                    headers,
                    method,
                    body
                })

                const data = await response.json()
                if (data.error) {
                    throw new Error(data.error.message);
                }
                return data
            } catch (error) {
                throw new Error(error)
            }
        }

        let selectedAEItems = []
        setProgressBar(true);

        Object.keys(importableChecked).map((key) => {
            const index = parseInt(key.replace("importableCheckbox", ""))
            selectedAEItems.push(index)
        })

        let selectedReviews = []
        selectedAEItems.forEach(index => {
            const importableReview = AEItems[index - 1]

            let photo = null
            if (importableReview.images && importableReview.images.length > 0) {
                photo = importableReview.images[importableReview.selectedImage]
            }

            const names = importableReview.buyerName.split(' ');

            selectedReviews.push({
                first_name: names[0],
                last_name: names[1] ?? '',
                description: importableReview.translatedContent,
                rating: importableReview.rating,
                photo: photo
            })
        })

        importReviews(selectedReviews).then((res) => {

            setSelectedItems([])
            setOffset(0)
            setListVersion(listVersion => listVersion + 1)

            setToastImported(true)
            clearAEFields()
        }).catch((e) => {
            setToastError(true)
            setProgressBar(false);
        })

    }, [AEItems, selectedAEItems, selectedOption, importableChecked, toastImported])


    async function listAEReviews(url, checkedReviewWithImagesOnly, selectedNameGeneratorStyle, nameGender, checkedSurname) {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
        const method = 'GET'

        const esc = encodeURIComponent

        let searchParams = "&images=" + checkedReviewWithImagesOnly;
        searchParams += "&namestyle=" + selectedNameGeneratorStyle;
        searchParams += "&namegender=" + nameGender;
        searchParams += "&namesurname=" + checkedSurname;


        try {
            const res = await fetch("/api/shop/aliexpress/review/list?url=" + esc(url) + searchParams, {
                headers,
                method,
            })
            return await res.json()
        } catch (e) {
            throw e
        }
    }

    const handleUrlChange = useCallback((newValue) => {
        setAliexpressUrl(newValue)
    }, [])

    const handleSubmit = useCallback((_event) => {
        setAELoading(true)
        listAEReviews(aliexpressUrl, checkedReviewWithImagesOnly, selectedNameGeneratorStyle, nameGender, checkedSurname).then((response) => {
            //setToastActive(true)
            setSelectedAEItems([])
            setImportableChecked({})
            setAEItems(response.reviews.map((review, index) => {
                return {...review, id: index + 1, selectedImage: 0}
            }))
            setOffset(0)
            setAELoading(false)
        }).catch((e) => {
            setToastError(true)
            setAELoading(false)
        })

    }, [aliexpressUrl, AELoading, selectedAEItems, importableChecked, checkedReviewWithImagesOnly, checkedSurname, nameGender, selectedNameGeneratorStyle])

    const clearAEFields = useCallback(() => {
        setAliexpressUrl('')
        setOptions([])
        updateText('')
        setSelectedOption({})
        setSelectedAEItems([])
        setImportableChecked({})
        setAEItems([])
        setNameGender('both')
        setProgressBar(false);
        setCheckedSurname(true);
        setSelectedNameGeneratorStyle('random');
        setCheckedReviewWithImagesOnly(false)
    }, [aliexpressUrl, options, selectedOption, selectedAEItems, importableChecked, AEItems, checkedReviewWithImagesOnly], checkedSurname, nameGender, selectedNameGeneratorStyle);

    const [modalActive, setModalActive] = useState(false)
    const [modalReview, setModalReview] = useState({thumbnailSet: null, buyerName: null, review: null})

    const ratingOptions = [
        {label: '5 star', value: 5},
        {label: '4 star', value: 4},
        {label: '3 star', value: 3},
        {label: '2 star', value: 2},
        {label: '1 star', value: 1},
    ];

    const [selectedReview, setSelectedReview] = useState({first_name: '', last_name: '', description: '', rating: 1, approved: false})

    const editReview = useCallback((item) => {
        setModifyReviewModalOpen(true);
        setSelectedReview(item);
    }, [items])

    const handleModifyReview = useCallback(() => {
        handleModifyReviewModalClose();

        setProgressBar(true);

        const review = {
            rating: selectedReview.rating,
            first_name: selectedReview.first_name,
            last_name: selectedReview.last_name,
            description: selectedReview.description,
            approved: selectedReview.approved
        }

        modifyReview(selectedReview.id, review).then(() => {
            setToastActive(true)
            setSelectedItems([])
            setOffset(0)
            setListVersion(listVersion => listVersion + 1)
            setProgressBar(false);
        }).catch((e) => {
            setToastError(true)
            setProgressBar(false);
        })
    }, [selectedReview]);

    const handleModalChange = useCallback((item) => {
        setModalActive(!modalActive)

        let thumbnailBuffer = []
        if (item.images && item.images.length) {
            item.images.forEach((image, index) => {
                thumbnailBuffer.push(<div key={'image_container' + index} className={`AEReviewImageContainer`}>
                        <div className={`AEThumbnailImageFrame  AEThumbnailImageFrameSelectable`}>
                            <img className="AEThumbnailImage" key={'thumbnail' + index} onClick={(e) => {
                                item.selectedImage = index
                                setModalActive(false)
                                setSelectedAEItems(selectedAEItems => selectedAEItems)
                            }} src={image} width="100px" alt={item.buyerName}/>
                        </div>
                    </div>
                )
            })
        }
        item.thumbnailSet = (
            <div className="AEThumbnailSetContainer">{thumbnailBuffer}</div>
        )

        setModalReview(item)
    }, [modalActive, setSelectedAEItems])

    const [appearance, setAppearance] = useState({
        language: 'en',
        base_layout_type: 'masonry',
        layout_type: 'tile_center_photo',
        color_star: '#D0021B',
        has_rating_summary: true,
        color_rating_bar: '#419890',
        color_rating_bar_background: '#dbdbdb',
        color_name: '#292929',
        color_review: '#292929',
        color_border: '#d0d3d0',
        color_background: '#0000FF',
        font_style: 'normal',
        font_size: '100',
        border_radius: false,
        button_color_title: '#292929',
        button_color_border: '#d0d3d0',
        button_color_background: '#ffffff',
        button_border_radius: false,
        box_shadow: false,
    })

    const handleAppearanceColorChanged = useCallback((key, color) => {
        setAppearance(appearance => ({...appearance, [key]: color}))
    }, [appearance])

    const handleSaveAppearance = useCallback((_event) => {
        setProgressBar(true);
        saveShopInfo({appearance}).then((resultProperties) => {
            setAppearance(resultProperties.appearance)
            setToastSaveDelay(toastSaveDelay => !toastSaveDelay)
            setProgressBar(false);
        }).catch((e) => {
            setToastError(true)
            setProgressBar(false);
        })


    }, [appearance])


    const handleAppearanceFontStyleChanged = useCallback((_checked, newValue) => {
        setAppearance(appearance => ({...appearance, font_style: newValue}))
    }, [appearance]);

    const handleAppearanceLayoutTypeChanged = useCallback((_checked, newValue) => {
        setAppearance(appearance => ({...appearance, layout_type: newValue}))
    }, [appearance]);

    const handleAppearanceBaseLayoutTypeChanged = useCallback((_checked, newValue) => {
        setAppearance(appearance => ({...appearance, base_layout_type: newValue}))
    }, [appearance]);

    /*const handleAppearanceFontStyleChanged = useCallback((value) => {
        setAppearance(appearance => ({...appearance, font_style: value}))
    }, [appearance])*/

    const handleAppearanceFontSizeChanged = useCallback((value) => {
        setAppearance(appearance => ({...appearance, font_size: value}))
    }, [appearance])


    const handleBorderRadiusChanged = useCallback((value) => {
        setAppearance(appearance => ({...appearance, border_radius: value}))
    }, [appearance]);

    const handleButtonBorderRadiusChanged = useCallback((value) => {
        setAppearance(appearance => ({...appearance, button_border_radius: value}))
    }, [appearance]);

    const handleBoxShadowChanged = useCallback((value) => {
        setAppearance(appearance => ({...appearance, box_shadow: value}))
    }, [appearance]);


    const handleHasRatingSummaryChanged = useCallback((value) => {
        setAppearance(appearance => ({...appearance, has_rating_summary: value}))
    }, [appearance]);
    //const [rangeValue, setRangeValue] = useState(100);

    /*const handleRangeSliderChange = useCallback(
        (value) => setRangeValue(value),
        [],
    );

     */
    const handleLanguageChanged = useCallback((value) => {
        setAppearance(appearance => ({...appearance, language: value}))
    }, [appearance]);


    const languageOptions = [
        {label: 'English', value: 'en'},
        {label: 'German', value: 'de'},
        {label: 'French', value: 'fr'},
        {label: 'Danish', value: 'da'},
        {label: 'Portuguese', value: 'pt'},
        {label: 'Italian', value: 'it'},
        {label: 'Spanish', value: 'es'},
        {label: 'Russian', value: 'ru'}
    ];

    const suffixStyles = {
        minWidth: '35px',
        textAlign: 'right',
    };

    async function createRecurringCharge(pricePlanName) {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token

        })
        const method = 'POST'
        const body = JSON.stringify({"pricePlanName": pricePlanName})

        try {
            const res = await fetch("/api/charge", {
                headers,
                method,
                body
            })
            return await res.json()
        } catch (e) {
            return e
        }
    }

    async function deleteRecurringCharge() {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token

        })
        const method = 'DELETE'

        try {
            const res = await fetch("/api/charge", {
                headers,
                method
            })
            return await res.json()
        } catch (e) {
            return e
        }
    }

    async function getChargeById(chargeId) {
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
        const method = 'GET'
        try {
            const res = await fetch("/api/charge/" + chargeId, {
                headers,
                method,
            })
            return await res.json()
        } catch (e) {
            return e
        }
    }

    const [deleteReviewModalOpen, setDeleteReviewModalOpen] = useState(false);
    const handleDeleteReviewModalClose = useCallback(() => setDeleteReviewModalOpen(false), []);

    const [modifyReviewModalOpen, setModifyReviewModalOpen] = useState(false);
    const handleModifyReviewModalClose = useCallback(() => setModifyReviewModalOpen(false), []);

    const handleSelectedReview = useCallback((key, newValue) => {
        if (key === 'rating') {
            newValue = parseInt(newValue)
        }
        setSelectedReview({...selectedReview, [key]: newValue});
    }, [selectedReview])

    const [modalOpen, setModalOpen] = useState(false);

    const [selectedPricePlan, setSelectedPricePlan] = useState(undefined);

    const handleModalClose = useCallback(() => {
        setModalOpen(false);
        setChargeFreeLoading(false);
        setChargeBasicLoading(false);
        setChargeStandardLoading(false);
    }, []);

    const [chargeFreeLoading, setChargeFreeLoading] = useState(false);
    const [chargeBasicLoading, setChargeBasicLoading] = useState(false);
    const [chargeStandardLoading, setChargeStandardLoading] = useState(false);

    const handleChargeClick = useCallback((pricePlanName) => {

        setSelectedPricePlan(pricePlanName);

        switch (pricePlanName) {
            case 'free':
                setChargeFreeLoading(true);
                break;
            case 'basic':
                setChargeBasicLoading(true);
                break;
            case 'standard':
                setChargeStandardLoading(true);
                break;
        }

        if (pricePlanName === 'free' || charged == 'standard') {
            setModalOpen(true);
        } else {
            createCharge(pricePlanName);
        }

        /*
        createRecurringCharge(pricePlanName).then((resultProperties) => {
            console.log(resultProperties)
            if (resultProperties.recurring_application_charge) {
                window.top.location.href = resultProperties.recurring_application_charge.confirmation_url
            }
        }).catch((e) => {
            setToastError(true)
        })

         */
    }, [modalOpen, charged, selectedPricePlan, chargeFreeLoading, chargeBasicLoading, chargeStandardLoading])

    const handleConfirmedChargeClick = useCallback(() => {
        if (selectedPricePlan === 'free') {
            deleteCharge();
        } else {
            createCharge(selectedPricePlan)
        }
    }, [selectedPricePlan])

    const chargeOptionSet = {
        "free": {
            label: 'Free', importLimit: '10'
        },
        "basic": {
            label: 'Basic', importLimit: '20'
        },
        "standard": {
            label: 'Standard', importLimit: '50'
        }
    };

    const chargeSet = (
        <Banner status="info">
            Your current plan is <b>{charged ? chargeOptionSet[charged].label  : 'Free'}</b>,
            which means you can import up to <b>{charged ? chargeOptionSet[charged].importLimit  : '10'}</b> reviews per product.
            {charged !== 'standard' ? ' If you want to increase the number of reviews, upgrade your plan!':''}
        </Banner>
    );

    const handleChargeDeleteClick = useCallback(() => {

        setModalOpen(true);

        /*deleteRecurringCharge().then((resultProperties) => {
            setToastActive(true)
            window.location.reload()
        }).catch((e) => {
            setToastError(true)
        })

         */
    }, [modalOpen])


    function deleteCharge() {
        deleteRecurringCharge().then((resultProperties) => {
            setToastActive(true)
            window.location.reload()
        }).catch((e) => {
            setToastError(true)
        })
    }


    function createCharge(pricePlanName) {
        createRecurringCharge(pricePlanName).then((resultProperties) => {
            if (resultProperties.recurring_application_charge) {
                window.top.location.href = resultProperties.recurring_application_charge.confirmation_url
            }
        }).catch((e) => {
            setToastError(true)
        })
    }


return (
<Frame>
    {importReviewsLoading}
    <Page
        title="PrismaCart - Reviews"
    >

            <Tabs selectedIndex={tabIndex} onSelect={handleTabIndexChange}>
                <TabList>
                    <Tab><div><Icon
                        source={SettingsMajorMonotone} /><p>Settings</p></div></Tab>
                    <Tab><div><Icon
                        source={ListMajorMonotone} /><p>Review list</p></div></Tab>
                    <Tab><div><Icon
                        source={IncomingMajorMonotone} /><p>Import AliExpress reviews</p></div></Tab>
                    <Tab><div><Icon
                        source={ColorsMajorMonotone} /><p>Appearance</p></div></Tab>
                    <Tab><div><Icon
                        source={CashDollarMajorMonotone} /><p>Pricing</p></div></Tab>
                    <Tab><div><Icon
                        source={QuestionMarkMajorMonotone} /><p>Help</p></div></Tab>
                </TabList>

                <TabPanel>
                    <Layout>
                    <Layout.AnnotatedSection
                        title="Review auto approve"
                        description="All review will be approved automatically with review auto approve option."
                    >
                        <SettingToggle
                            action={{
                                content: contentStatus,
                                onAction: handleToggle,
                            }}
                            enabled={active}
                        >
                            Review auto approve is <TextStyle variation="strong">{textStatus}</TextStyle>.
                            <br/>{autoApproveDescription}
                        </SettingToggle>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection
                        title="Review email notification"
                        description="By this you you will get email notification after a new review is created."
                    >
                        <SettingToggle
                            action={{
                                content: reviewEmailContentStatus,
                                onAction: handleReviewEmailToggle,
                            }}
                            enabled={reviewEmailActive}
                        >
                            Review email notification is <TextStyle variation="strong">{reviewEmailTextStatus}</TextStyle>.
                            <br/>{reviewEmailDescription}
                        </SettingToggle>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection
                        title="Review request email"
                        description="Encourage your customers to leave a review with automated email requests sent after fulfilled orders."
                    >
                        <SettingToggle
                            action={{
                                content: reviewEmailRequestContentStatus,
                                onAction: handleReviewEmailRequestToggle,
                            }}
                            enabled={reviewEmailRequestActive}
                        >
                            Review email request is <TextStyle variation="strong">{reviewEmailRequestTextStatus}</TextStyle>.
                            <br/>{reviewEmailRequestDescription}
                        </SettingToggle>
                    </Layout.AnnotatedSection>
                    </Layout>
                </TabPanel>

                <TabPanel>
                <Layout>
                    <Layout.Section>
                        <Card title="Reviews">
                            <ResourceList
                                resourceName={{singular: 'review', plural: 'reviews'}}
                                selectedItems={selectedItems}
                                onSelectionChange={setSelectedItems}
                                loading={loading}
                                promotedBulkActions={promotedBulkActions}
                                bulkActions={bulkActions}
                                filterControl={
                                    <Filters
                                        queryValue={queryValue}
                                        filters={filters}
                                        appliedFilters={appliedFilters}
                                        onQueryChange={handleFiltersQueryChange}
                                        onQueryClear={handleQueryValueRemove}
                                        onClearAll={handleFiltersClearAll}

                                    />
                                }
                                items={items}
                                renderItem={(item) => {
                                    const {id, description, rating, product_id, first_name, last_name, approved, email, createdAt, updatedAt, photo_url='', product_admin_url, status} = item
                                    let thumbnail
                                    let approve_badge

                                    if (photo_url) {
                                        thumbnail = (
                                            <div className="AEReviewImageContainer">
                                                <div className="AEThumbnailImageFrame">
                                                    <img className="AEThumbnailImage"
                                                         src={photo_url} width="100px"
                                                         alt={first_name}/>
                                                </div>
                                            </div>
                                        )
                                    }
                                    if (status === 'deleted') {
                                        approve_badge = (
                                            <Badge>Deleted</Badge>
                                        )
                                    } else if (approved) {
                                        approve_badge = (
                                            <Badge status="success">Approved</Badge>
                                        )
                                    } else {
                                        approve_badge = (
                                            <Badge status="attention">Not approved</Badge>
                                        )
                                    }
                                    const created_badge = (
                                        <div>{createdAt}</div>
                                    )
                                    item.thumbnail = thumbnail
                                    const starStyle = {
                                        "--rating": Math.round((rating)) // note the capital 'W' here
                                    }
                                    return (
                                        <ResourceList.Item
                                            id={id}
                                            description={description}
                                            rating={rating}
                                            product_id={product_id}
                                            first_name={first_name}
                                            last_name={last_name}
                                            approved={approved}
                                            photo_url={photo_url}
                                            thumbnail={thumbnail}
                                            email={email}
                                            createdAt={createdAt}
                                            updatedAt={updatedAt}
                                            product_admin_url={product_admin_url}
                                            accessibilityLabel={`View details for ${first_name}`}
                                        >
                                            {thumbnail}

                                            <div className="AEReviewContent">
                                                <div className="starContainer">
                                                    <div className="Stars" style={starStyle}
                                                         aria-label="Rating of this product is 2.3 out of 5.">
                                                    </div>
                                                </div>
                                                <h3>
                                                    <TextStyle variation="strong">{first_name  + (last_name ? ' ' + last_name : '')}</TextStyle>
                                                </h3>
                                                <div className="textContainer">{description}
                                                </div>

                                                <Stack>
                                                    <Stack.Item fill>
                                                        <Link url={product_admin_url} external>
                                                            Related Product
                                                        </Link>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        {created_badge}
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        {approve_badge}
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <Button icon={EditMinor} plain={true} size="slim" onClick={() => editReview(item)}>Edit review</Button>
                                                    </Stack.Item>
                                                </Stack>

                                            </div>
                                        </ResourceList.Item>
                                    )
                                }}
                            />
                            <div className="Polaris-ResourceList__FiltersWrapper">
                                <Pagination

                                    label="Results"
                                    hasPrevious={hasPrevious}
                                    onPrevious={() => {
                                        setOffset(offset - limit)
                                    }}
                                    hasNext={hasNext}
                                    onNext={() => {

                                        setOffset(offset + limit)
                                    }}
                                />
                            </div>

                        </Card>
                    </Layout.Section>
                </Layout>
                </TabPanel>
                <TabPanel>
                    <Layout>


                    <Layout.AnnotatedSection
                        title="Import from Aliexpress"
                        description="Import product reviews from Aliexpress.com. The reviews will appear on the product page in few seconds after you imported them. It should be noted that existing reviews will be deleted during import."
                    >
                        <Card sectioned>
                            <TextContainer>
                                <Banner status="info">
                                 Watch our instructional{' '}
                                    <Link url="https://youtu.be/FDWnTU9Dk3A" external>video</Link>{' '}about how import AliExpress reviews!
                                </Banner>
                            </TextContainer>
                            <TextContainer>
                                &nbsp;
                            </TextContainer>
                            <Form onSubmit={handleSubmit}>
                                <FormLayout>
                                    <Autocomplete
                                        options={options}
                                        selected={selectedOptions}
                                        onSelect={updateSelection}
                                        loading={loading}
                                        textField={textFields}
                                    />
                                    <div>
                                        {selectedProductBox}
                                    </div>
                                    <TextField
                                        label="Aliexpress product url"
                                        placeholder="https://www.aliexpress.com/item/4000377826977.html"
                                        type="url"
                                        onChange={handleUrlChange}
                                        value={aliexpressUrl}
                                    />

                                    <FormLayout.Group>
                                    <Select
                                        label="Name style"
                                        options={nameGeneratorStylesOptions}
                                        onChange={handleNameGeneratorStyles}
                                        value={selectedNameGeneratorStyle}
                                    />

                                        <Select
                                            label="Genders of names"
                                            options={nameGenderOptions}
                                            onChange={handleNameGender}
                                            value={nameGender}
                                        />

                                    </FormLayout.Group>
                                    <FormLayout.Group>
                                    <Checkbox
                                        helpText="Generate names with surnames"
                                        label="Add surname"
                                        checked={checkedSurname}
                                        onChange={handleSurname}
                                    />

                                        <Checkbox
                                            label="Reviews with images only"
                                            helpText="Get reviews that are contains images"
                                            checked={checkedReviewWithImagesOnly}
                                            onChange={handleReviewWithImagesOnly}
                                        />
                                    </FormLayout.Group>
                                    {chargeSet}
                                    <ButtonGroup>
                                        <Button disabled={!aliexpressUrl} submit>Load Aliexpress product reviews</Button>
                                        <Button onClick={clearAEFields} plain>Reset fields</Button>
                                    </ButtonGroup>

                                    <Button loading={progressBar} disabled={importAEButtonDisabled} fullWidth primary={true} onClick={() => handleImportAEReviews()}>Import selected reviews</Button>
                                </FormLayout>
                            </Form>
                            <ResourceList
                                resourceName={{singular: 'AliExpress review', plural: 'AliExpress reviews'}}
                                selectedItems={selectedAEItems}
                                loading={AELoading}
                                items={AEItems}
                                renderItem={(item) => {
                                    return (
                                        <CustomAEImportItem
                                            item={item}
                                            importableChecked={importableChecked}
                                            handleImportableCheckeChanged={handleImportableCheckedChange}
                                            handleModalChange={handleModalChange}
                                            addingDisabled={addingDisabled}
                                        />
                                        //resource item import
                                    )
                                }}
                            />

                        </Card>
                        <Modal
                            open={modalActive}
                            onClose={handleModalChange}
                            title="Select the photo you want to import with the review"
                        >
                            <Modal.Section>
                                <TextContainer>
                                    {modalReview.thumbnailSet}
                                </TextContainer>
                            </Modal.Section>
                        </Modal>
                    </Layout.AnnotatedSection>
                    </Layout>
                </TabPanel>

                <TabPanel>
                    <Layout>

                    <Layout.AnnotatedSection
                        title="Appearance"
                        description="This page allows to customize your review's appearance by adjusting the colors and styles of your review elements."
                    >
                        <Card sectioned>
                            <TextContainer>
                            <Banner status={"info"}>
                                <p>Please keep in mind the modification needs a few seconds for it to take effect.</p>
                            </Banner>
                            </TextContainer>
                            &nbsp;
                            <Form onSubmit={handleSaveAppearance}>
                                <FormLayout>
                                    <Subheading>Layout</Subheading>

                                    <Stack distribution="fillEvenly">>
                                        <Stack.Item>
                                            Base layout type:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <RadioButton
                                                label="Masonry"
                                                helpText="Masonry-style fluid grid layout."
                                                checked={appearance.base_layout_type === 'masonry'}
                                                id="masonry"
                                                name="base_layout_type"
                                                onChange={handleAppearanceBaseLayoutTypeChanged}
                                            />
                                            <RadioButton
                                                label="Carousel"
                                                helpText="Carousel slider layout."
                                                checked={appearance.base_layout_type === 'carousel'}
                                                id="carousel"
                                                name="base_layout_type"
                                                onChange={handleAppearanceBaseLayoutTypeChanged}
                                            />
                                            <RadioButton
                                                label="Simple"
                                                helpText="Simple vertical layout."
                                                checked={appearance.base_layout_type === 'simple'}
                                                id="simple"
                                                name="base_layout_type"
                                                onChange={handleAppearanceBaseLayoutTypeChanged}
                                            />
                                        </Stack.Item>
                                    </Stack>

                                    <div style={{'display': appearance.base_layout_type !== 'simple' ? 'block' : 'none'}}>
                                    <Stack distribution="fillEvenly">>
                                        <Stack.Item>
                                            Review layout type:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <RadioButton
                                                label="Tiles with centered photo"
                                                helpText="Tiles with vertically centered photos."
                                                checked={appearance.layout_type === 'tile_center_photo'}
                                                id="tile_center_photo"
                                                name="layout_type"
                                                onChange={handleAppearanceLayoutTypeChanged}
                                            />
                                            <RadioButton
                                                label="Tiles with upper photo"
                                                helpText="Tiles with photos on top of it."
                                                checked={appearance.layout_type === 'tile_upper_photo'}
                                                id="tile_upper_photo"
                                                name="layout_type"
                                                onChange={handleAppearanceLayoutTypeChanged}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                    </div>

                                    <div className={'separator'}/>

                                    <Subheading>Stars</Subheading>

                                    <Stack>
                                        <Stack.Item fill>
                                            Color of stars:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <CustomColorPicker element_key='color_star' color={appearance.color_star} handleColorChanged={handleAppearanceColorChanged}/>
                                        </Stack.Item>

                                    </Stack>

                                    <div className={'separator'}/>

                                    <Subheading>Summary of star ratings and reviews</Subheading>

                                    <Stack spacing="extraLoose">
                                        <Stack.Item fill>
                                            Enable summary of star ratings and reviews:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Checkbox
                                                label="Summary of star ratings and reviews"
                                                labelHidden={true}
                                                checked={appearance.has_rating_summary}
                                                onChange={handleHasRatingSummaryChanged}
                                            />
                                        </Stack.Item>
                                    </Stack>

                                    <div style={{'display': appearance.has_rating_summary ? 'block' : 'none'}}>
                                        <Stack>
                                            <Stack.Item fill>
                                                Color of rating bars:
                                            </Stack.Item>
                                            <Stack.Item>
                                                <CustomColorPicker element_key='color_rating_bar' color={appearance.color_rating_bar} handleColorChanged={handleAppearanceColorChanged}/>
                                            </Stack.Item>

                                        </Stack>
                                    </div>
                                    <div style={{'display': appearance.has_rating_summary ? 'block' : 'none'}}>
                                        <Stack>
                                            <Stack.Item fill>
                                                Background color of rating bars:
                                            </Stack.Item>
                                            <Stack.Item>
                                                <CustomColorPicker element_key='color_rating_bar_background' color={appearance.color_rating_bar_background} handleColorChanged={handleAppearanceColorChanged}/>
                                            </Stack.Item>

                                        </Stack>
                                    </div>

                                    <div className={'separator'}/>

                                    <Subheading>Reviews</Subheading>

                                    <Stack>
                                        <Stack.Item fill>
                                            Color of name:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <CustomColorPicker element_key='color_name' color={appearance.color_name} handleColorChanged={handleAppearanceColorChanged}/>
                                        </Stack.Item>
                                    </Stack>

                                    <Stack>
                                        <Stack.Item fill>
                                            Color of review text:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <CustomColorPicker element_key='color_review' color={appearance.color_review} handleColorChanged={handleAppearanceColorChanged}/>
                                        </Stack.Item>
                                    </Stack>



                                    <Stack>
                                        <Stack.Item fill>
                                            Background color:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <CustomColorPicker element_key='color_background' color={appearance.color_background} handleColorChanged={handleAppearanceColorChanged}/>
                                        </Stack.Item>
                                    </Stack>

                                    <Stack>
                                        <Stack.Item fill>
                                            Font style:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <RadioButton
                                                label="Normal"
                                                helpText="Normal font style."
                                                checked={appearance.font_style === 'normal'}
                                                id="normal"
                                                name="font_style"
                                                onChange={handleAppearanceFontStyleChanged}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <RadioButton
                                                label="Italic"
                                                helpText="Italic font style."
                                                id="italic"
                                                name="font_style"
                                                checked={appearance.font_style === 'italic'}
                                                onChange={handleAppearanceFontStyleChanged}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                    <Stack spacing="extraLoose">
                                        <Stack.Item>
                                            Font size:
                                        </Stack.Item>
                                        <Stack.Item fill>
                                            <RangeSlider labelHidden={true}
                                                output
                                                label="Font size"
                                                min={80}
                                                max={120}
                                                step={5}
                                                value={appearance.font_size}
                                                onChange={handleAppearanceFontSizeChanged}

                                                suffix={<p style={suffixStyles}>{appearance.font_size}%</p>}
                                            />
                                        </Stack.Item>
                                    </Stack>


                                    <div style={{'display': appearance.base_layout_type !== 'simple' ? 'block' : 'none'}}>

                                    <Stack>
                                        <Stack.Item fill>
                                            Color of borders:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <CustomColorPicker element_key='color_border' color={appearance.color_border} handleColorChanged={handleAppearanceColorChanged}/>
                                        </Stack.Item>
                                    </Stack>
                                    </div>
                                    <div style={{'display': appearance.base_layout_type !== 'simple' ? 'block' : 'none'}}>
                                    <Stack spacing="extraLoose">
                                        <Stack.Item fill>
                                            Rounded corners:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Checkbox
                                                label="Rounded corners"
                                                labelHidden={true}
                                                checked={appearance.border_radius}
                                                onChange={handleBorderRadiusChanged}
                                            />
                                        </Stack.Item>
                                    </Stack>

                                    </div>
                                    <div style={{'display': appearance.base_layout_type !== 'simple' ? 'block' : 'none'}}>

                                    <Stack spacing="extraLoose">
                                        <Stack.Item fill>
                                            Shadow:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Checkbox
                                                label="Shadow"
                                                labelHidden={true}
                                                checked={appearance.box_shadow}
                                                onChange={handleBoxShadowChanged}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                    </div>
                                    <div className={'separator'}/>

                                    <Subheading>Buttons</Subheading>

                                    <Stack>
                                        <Stack.Item fill>
                                            Color of button title:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <CustomColorPicker element_key='button_color_title' color={appearance.button_color_title} handleColorChanged={handleAppearanceColorChanged}/>
                                        </Stack.Item>
                                    </Stack>

                                    <Stack>
                                        <Stack.Item fill>
                                            Color of button borders:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <CustomColorPicker element_key='button_color_border' color={appearance.button_color_border} handleColorChanged={handleAppearanceColorChanged}/>
                                        </Stack.Item>
                                    </Stack>

                                    <Stack>
                                        <Stack.Item fill>
                                            Background color of button:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <CustomColorPicker element_key='button_color_background' color={appearance.button_color_background} handleColorChanged={handleAppearanceColorChanged}/>
                                        </Stack.Item>
                                    </Stack>

                                    <Stack spacing="extraLoose">
                                        <Stack.Item fill>
                                            Rounded button corners:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Checkbox
                                                label="Rounded button corners"
                                                labelHidden={true}
                                                checked={appearance.button_border_radius}
                                                onChange={handleButtonBorderRadiusChanged}
                                            />
                                        </Stack.Item>
                                    </Stack>

                                    <div className={'separator'}/>
                                    <Subheading>Language</Subheading>

                                    <Stack spacing="extraLoose" distribution="fillEvenly">
                                        <Stack.Item>
                                            Language:
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Select
                                                label="Language"
                                                labelHidden={true}
                                                options={languageOptions}
                                                onChange={handleLanguageChanged}
                                                value={appearance.language}
                                            />
                                        </Stack.Item>
                                    </Stack>


                                    <Button loading={progressBar} primary={true} submit>Save</Button>
                                </FormLayout>
                            </Form>
                        </Card>
                    </Layout.AnnotatedSection>
                    </Layout>

                </TabPanel>

                <TabPanel>
                    <Layout>
                        <Layout.Section>
                            <p>Your current plan: <b>{charged ? chargeOptionSet[charged].label  : 'Free'}</b></p>
                            <p>&nbsp;</p>
                        </Layout.Section>
                    </Layout>
                    <Layout>
                        <Layout.Section oneThird>
                            <Card title="Free" sectioned subdued={!charged}>
                                <p>Getting started with review import tool to build your business and grow your audience.</p>
                                <p>&nbsp;</p>
                                <DisplayText size="large">Free</DisplayText>
                                <p>&nbsp;</p>
                                <List type="bullet">
                                    <List.Item>Up to 10 reviews scan per product</List.Item>
                                    <List.Item>Reviews, ratings & popup widgets</List.Item>
                                </List>
                                <p>&nbsp;</p>
                                <Button loading={chargeFreeLoading} disabled={!charged || chargeFreeLoading} onClick={() => handleChargeClick("free")} primary>Try for free</Button>
                            </Card>
                        </Layout.Section>

                        <Layout.Section oneThird>
                            <Card title="Basic" sectioned subdued={charged == "basic"}>
                                <p>Extended review scan and customization tool for businesses that want to grow faster.</p>
                                <p>&nbsp;</p>
                                <DisplayText size="large">$4.99/month</DisplayText>
                                <p>&nbsp;</p>
                                <List type="bullet">
                                    <List.Item>Up to 20 reviews scan per product</List.Item>
                                    <List.Item>Reviews, ratings & popup widgets</List.Item>
                                    <List.Item>Customization tab</List.Item>
                                </List>
                                <p>&nbsp;</p>
                                <Button loading={chargeBasicLoading} disabled={charged == "basic" || chargeBasicLoading} onClick={() => handleChargeClick("basic")} primary>Subscribe</Button>
                            </Card>
                        </Layout.Section>

                        <Layout.Section oneThird>
                            <Card title="Standard" sectioned subdued={charged == "standard"}>
                                <p>Advanced features for pros who need more reviews.</p>
                                <p>&nbsp;</p>
                                <DisplayText size="large">$9.99/month</DisplayText>
                                <p>&nbsp;</p>
                                <List type="bullet">
                                    <List.Item>Up to 50 reviews scan per product</List.Item>
                                    <List.Item>Reviews, Ratings & Popup widgets</List.Item>
                                    <List.Item>Customization tab</List.Item>
                                </List>
                                <p>&nbsp;</p>
                                <Button loading={chargeStandardLoading} disabled={charged == "standard" || chargeStandardLoading} onClick={() => handleChargeClick("standard")} primary>Subscribe</Button>
                            </Card>
                        </Layout.Section>
                        </Layout>

                </TabPanel>
                <TabPanel>
                    <TabHelp/>
                </TabPanel>


            </Tabs>
        <Layout>
            <Layout.Section>
                <FooterHelp>
                    Have a question? Need more help? Please, feel free to {' '}
                    <Link external url="mailto:https://polaris.shopify.com">send us an email</Link>.
                </FooterHelp>
            </Layout.Section>
            {toastMarkup}
            {toastSaveDelayMarkup}
            {toastErrorMarkup}
            {toastImportedMarkup}
            {toastLimitReachedMarkup}
        </Layout>

    </Page>
    <Modal
        open={modalOpen}
        title="Downgrading Your Pricing Plan"
        primaryAction={{
            content: 'Downgrade Pricing Plan',
            onAction: handleConfirmedChargeClick,
        }}
        secondaryActions={[
            {
                content: 'Cancel',
                onAction: handleModalClose,
            },
        ]}
        onClose={handleModalClose}
    >
        <Modal.Section>
            <TextContainer>
                <p>
                    Please, keep in mind if you downgrade your pricing plan, the increased limitations will also have effect on the existing reviews.
                    For example the review per product limit will reduce the number of existing reviews for older products as well as the newer once.
                </p>
            </TextContainer>
        </Modal.Section>
    </Modal>
    <Modal
        open={deleteReviewModalOpen}
        title="Delete reviews"
        primaryAction={{
            content: 'Delete reviews',
            onAction: handleDeleteReviews,
            destructive: true
        }}
        secondaryActions={[
            {
                content: 'Cancel',
                onAction: handleDeleteReviewModalClose,
            },
        ]}
        onClose={handleDeleteReviewModalClose}
    >
        <Modal.Section>
            <TextContainer>
                <p>Are you sure you want to delete the selected reviews?</p>
                <p>The selected reviews will be deleted immediately. You can't undo this action.</p>
            </TextContainer>
        </Modal.Section>
    </Modal>

    <Modal
        open={modifyReviewModalOpen}
        title="Edit review"
        primaryAction={{
            content: 'Save',
            onAction: handleModifyReview,
            destructive: false,
            disabled: selectedReview.first_name.length === 0
        }}
        secondaryActions={[
            {
                content: 'Cancel',
                onAction: handleModifyReviewModalClose,
            },
        ]}
        onClose={handleModifyReviewModalClose}
    >
        <Modal.Section>
            <FormLayout>
                <Select
                    label="Rating"
                    options={ratingOptions}
                    value={selectedReview.rating}
                    onChange={event => handleSelectedReview('rating', event)}
                />
                <TextField
                    label="First name"
                    value={selectedReview.first_name}
                    onChange={event => handleSelectedReview('first_name', event)}
                    error={selectedReview.first_name.length === 0 ? 'Field is required!' : ''}
                />
                <TextField
                    label="Last name"
                    value={selectedReview.last_name}
                    onChange={event => handleSelectedReview('last_name', event)}
                />
                <TextField
                    label="Review"
                    value={selectedReview.description}
                    onChange={event => handleSelectedReview('description', event)}
                    multiline={4}
                />
                <Checkbox
                    label="Approved"
                    checked={selectedReview.approved}
                    onChange={event => handleSelectedReview('approved', event)}
                />
            </FormLayout>
        </Modal.Section>
    </Modal>
</Frame>
)
}