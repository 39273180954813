import React from 'react'
import {Badge, Checkbox, Stack, TextStyle} from "@shopify/polaris"

const CustomAEImportItem = (props) => {

    const item = props.item
    const importableChecked = props.importableChecked
    const handleImportableCheckedChange = props.handleImportableCheckeChanged
    const handleModalChange = props.handleModalChange
    const addingDisabled = props.addingDisabled

    const {id, buyerName, rating, images, selectedImage, translatedContent} = item
    let thumbnail
    let morePhoto
    let importBadge
    let morePhotoBadge

    let bgStyle = {
        backgroundColor: "#FFF",
        padding: "10px"
    }

    if (importableChecked['importableCheckbox' + id]) {
        bgStyle.backgroundColor= "#ebf5e6"

        importBadge = (
            <Badge status="success" progress="complete">Selected for import</Badge>
        )
    } else {
        importBadge = (
            <Badge progress="incomplete">Skipped for import</Badge>
        )
    }

    if (addingDisabled && !importableChecked['importableCheckbox' + id]) {
        bgStyle.backgroundColor= "#f1f1f1"
    }

        if (images && images.length) {

        if (images.length > 1) {
            morePhotoBadge = (
                <Badge status="default" >More photo available</Badge>
            )
            morePhoto = (
                <div className="AEReviewMorePhoto">
                    <svg height="20" width="40">
                        <circle cx="10" cy="10" r="3" stroke="#6f7d8e"
                                strokeWidth="1" fill="white"></circle>
                        <circle cx="20" cy="10" r="3" stroke="#6f7d8e"
                                strokeWidth="1" fill="white"></circle>
                        <circle cx="30" cy="10" r="3" stroke="#6f7d8e"
                                strokeWidth="1" fill="white"></circle>
                        Sorry, your browser does not support inline SVG.
                    </svg>
                </div>
            )
        }
        thumbnail = (
            <div className="AEReviewImageContainer">
                <div className="AEThumbnailImageFrame">
                    <img className="AEThumbnailImage"
                         src={images[selectedImage]} width="100px"
                         onClick={() => handleModalChange(item)}
                         alt={buyerName}/>
                </div>
                {morePhoto}
            </div>
        )
    }
    item.thumbnail = thumbnail
    const starStyle = {
        "--rating": rating
    }

    return (
        <div style={bgStyle}>


                <div className="AEReviewContent">
                    <div className="AECheckboxContainer">
                    <Checkbox
                        label=""
                        id={'importableCheckbox' + id}
                        checked={importableChecked['importableCheckbox' + id]}
                        onChange={handleImportableCheckedChange}
                        disabled={addingDisabled && !importableChecked['importableCheckbox' + id]}
                    />
                    </div>

                    {thumbnail}


                    <div className="starContainer">
                        <div className="Stars" style={starStyle}
                             aria-label="Rating of this product is 2.3 out of 5.">
                        </div>
                    </div>
                    <h3>
                        <TextStyle variation="strong">{buyerName}</TextStyle>
                    </h3>
                    <div>{translatedContent}

                    </div>

                </div>


            <Stack>
                <Stack.Item fill>
                    {morePhotoBadge}
                </Stack.Item>
                <Stack.Item>
                    {importBadge}
                </Stack.Item>
            </Stack>

        </div>
    )
}

export default CustomAEImportItem;